import React, { useContext, useEffect, useRef, useState } from "react";
import Panel from "../UI/Panel/Panel";
import { UserContext } from "../../contexts/UserContext";
import moment from "moment";
import { GameEventsContext } from "../../contexts/GameEventContext";

function EFLTeamNames({ game }) {
  const { user } = useContext(UserContext);
  const { events: opta_events } = useContext(GameEventsContext);
  const [kickOff, setKickOff] = useState();
  const [period, setPeriod] = useState();
  const timeRef = useRef();
  useEffect(() => {
    let periods = opta_events?.filter(
      (ev) => (ev.typeId === 30 || ev.typeId === 32) && ev.periodId < 14
    );

    let latest = periods?.at(-1);
    let latest_event = opta_events?.at(-1);
    if (latest) {
      if (latest.typeId === 30) {
        //end period
        setKickOff();
        switch (latest.periodId) {
          case 1:
            setPeriod({ half: "HT" });
            break;
          case 2:
            if (
              periods?.findIndex(
                (ev) =>
                  ev.qualifier.findIndex((q) => q.qualifierId === 209) > -1
              ) > -1
            ) {
              setPeriod({ half: "FT" });
              break;
            }
          default:
            setPeriod({ half: "" });
            break;
        }
      } else if (latest.typeId === 32) {
        //start period

        setKickOff(latest);

        switch (latest.periodId) {
          case 1:
            setPeriod({
              half: "1st Half",
            });
            break;
          case 2:
            setPeriod({
              half: "2nd Half",
              time:
                latest_event?.timeMin?.toString()?.padStart(2, "0") +
                ":" +
                latest_event?.timeSec?.toString()?.padStart(2, "0"),
            });
            break;
          case 3:
            setPeriod({
              half: "AET 1",
              time:
                latest_event?.timeMin?.toString()?.padStart(2, "0") +
                ":" +
                latest_event?.timeSec?.toString()?.padStart(2, "0"),
            });
            break;
          case 4:
            setPeriod({
              half: "AET 2",
              time:
                latest_event?.timeMin?.padStart(2, "0") +
                ":" +
                latest_event?.timeSec?.padStart(2, "0"),
            });
            break;
          default:
            setPeriod({ time: "" });
        }
      }
    }
  }, [opta_events]);

  useEffect(() => {
    let interval = setInterval(() => {
      if (kickOff) {
        let duration = moment.duration(
          moment().utc().diff(moment(kickOff?.timeStamp).utc())
        );
        if (kickOff.periodId === 2) {
          duration.add(45, "minutes");
        }

        // console.log(kickOff);

        timeRef.current.innerHTML =
          (duration.hours() * 60 + duration.minutes())
            ?.toString()
            ?.padStart(2, "0") +
          ":" +
          duration.seconds()?.toString()?.padStart(2, "0");
      } else {
        timeRef.current.innerHTML = "";
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [kickOff, timeRef]);
  return (
    <Panel className="flex max-h-[100px] items-center overflow-hidden">
      <div className="flex flex-col w-full">
        <div className="flex justify-center text-xs">
          <span className="mr-1">
            Date: <b>{moment(game?.date)?.format("ddd Do MMM YYYY")}</b>
          </span>
          <span className="mr-1">
            KO: <b>{moment(game?.time, "HH:mmZ")?.format("HH:mm")}</b>
          </span>
          <span className="mr-1">
            Venue: <b>{game?.stadium?.name || game?.stadium?.opta_name}</b>
          </span>
        </div>
        <div className="flex w-full">
          <div className="flex items-center justify-end w-2/6 relative">
            <div
              className="absolute left-0 w-[80px] min-h-[500px] ml-auto border dark:border-none rotate-[-150deg] shadow"
              style={{
                backgroundColor:
                  game?.home_team?.variables?.[game?.home_team_kit + "_kit_bg"],
              }}
            ></div>

            <div className="flex items-center">
              <div className="flex flex-col items-end">
                {game?.home_team?.name}
                <div className="flex text-xs justify-end">
                  <Scorers
                    opta_events={opta_events}
                    team={game?.home_team}
                    other_team={game?.away_team}
                  />
                </div>
              </div>
              <img
                className="w-10"
                src={
                  (window.ENV?.REACT_APP_PROJECT_SERVER ||
                    process.env.REACT_APP_PROJECT_SERVER) +
                  "/api/assets/images/" +
                  (user?.teams?.[0]?.owner ?? user?._id) +
                  "/" +
                  game?.home_team?.variables?.badge
                }
                alt="Team Badge"
              />
            </div>
          </div>
          <div className="flex justify-center w-2/6 text-4xl font-bold items-center space-x-5">
            <div className="flex">
              {opta_events?.filter(
                (e) =>
                  e.typeId === 16 &&
                  ((e.contestantId === game?.home_team?.opta_ID &&
                    e?.qualifier?.findIndex((q) => q.qualifierId === 28) ===
                      -1) ||
                    (e.contestantId === game?.away_team?.opta_ID &&
                      e?.qualifier?.findIndex((q) => q.qualifierId === 28) >
                        -1))
              )?.length ?? 0}
            </div>
            <div className="flex text-sm flex-col items-center">
              <div>{period?.half}</div>
              <div ref={timeRef}></div>
            </div>
            <div className="flex">
              {opta_events?.filter(
                (e) =>
                  e.typeId === 16 &&
                  ((e.contestantId === game?.away_team?.opta_ID &&
                    e?.qualifier?.findIndex((q) => q.qualifierId === 28) ===
                      -1) ||
                    (e.contestantId === game?.home_team?.opta_ID &&
                      e?.qualifier?.findIndex((q) => q.qualifierId === 28) >
                        -1))
              )?.length ?? 0}
            </div>
          </div>
          <div className="flex items-center w-2/6 relative">
            <img
              className="w-10"
              src={
                (window.ENV?.REACT_APP_PROJECT_SERVER ||
                  process.env.REACT_APP_PROJECT_SERVER) +
                "/api/assets/images/" +
                (user?.teams?.[0]?.owner ?? user?._id) +
                "/" +
                game?.away_team?.variables?.badge
              }
              alt="Team Badge"
            />
            <div className="flex items-center">
              <div className="flex flex-col">
                {game?.away_team?.name}
                <div className="flex text-xs">
                  <Scorers
                    opta_events={opta_events}
                    team={game?.away_team}
                    side="right"
                    other_team={game?.home_team}
                  />
                </div>
              </div>
            </div>
            <div
              className="absolute right-0 w-[80px] min-h-[500px] ml-auto border dark:border-none rotate-[150deg] shadow"
              style={{
                backgroundColor:
                  game?.away_team?.variables?.[game?.away_team_kit + "_kit_bg"],
              }}
            ></div>
          </div>
        </div>
      </div>
    </Panel>
  );
}

function Scorers({ opta_events, team, other_team, side = "left" }) {
  let filtered = opta_events?.filter(
    (ev) =>
      ev.typeId === 16 &&
      ((ev.contestantId === team.opta_ID &&
        ev.qualifier?.findIndex((q) => q.qualifierId === 28) === -1) ||
        (ev.contestantId === other_team?.opta_ID &&
          ev.qualifier?.findIndex((q) => q.qualifierId === 28) > -1))
  );
  filtered = Object.groupBy(filtered, ({ playerId }) => playerId);
  let keys = Object.keys(filtered);
  return (
    keys?.length > 0 &&
    keys?.map((key, index) => {
      let player = filtered[key];

      return (
        <span
          className={side === "left" ? "ml-1" : side === "right" ? "mr-1" : ""}
        >
          {player[0]?.playerName}{" "}
          {player?.map((ev, goal_index) => {
            return (
              <>
                {ev?.timeMin + 1}'
                {ev.qualifier?.findIndex((q) => q.qualifierId === 9) > -1 &&
                  " (P)"}
                {ev.qualifier?.findIndex((q) => q.qualifierId === 28) > -1 &&
                  " (OG)"}
                {(index < keys?.length - 1 ||
                  goal_index < player?.length - 1) &&
                  ", "}
              </>
            );
          })}
        </span>
      );
    })
  );
}

export default EFLTeamNames;
